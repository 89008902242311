import {
    DatetimePicker,
    DropdownMenu as DropdownMenuWap,
    DropdownItem as DropdownItemWap,
    Swipe,
    SwipeItem,
    Tab,
    Tabs,
    Collapse,
    CollapseItem,
    Popup
} from 'vant';
// import 'vant/lib/index.css';
import micros from "../../micros/index.js"
import {
    cookie,
    config,
    common
} from '@/utils'
import {
    mapMutations
} from "vuex"
import {
    getToken
} from "../../utils/other/auth";
import api from "../../api/user";
export default {
    components: {
        Collapse,
        CollapseItem,
        DropdownMenuWap,
        DropdownItemWap
    },

    data() {
        return {
            cities: false,
            cityList: [],
            tapIndex: "首页",
            tabList: [{
                    name: '首页',
                    type: 0,
                    linkUrl: "/"
                },
                {
                    name: '公证服务',
                    child: [{
                        name: '作品存证',
                        linkUrl: ""
                    }, {
                        name: '网页取证',
                        linkUrl: ""
                    }, {
                        name: '公证翻译',
                        linkUrl: ""
                    }, {
                        name: '公证提取',
                        linkUrl: ""
                    }, {
                        name: '零跑腿公证',
                        linkUrl: ""
                    }, ],
                    type: 1
                },
                {
                    name: '知产交易',
                    child: [{
                        name: '版权交易',
                        linkUrl: "/commodity/buy?view=false&type=0"
                    }, {
                        name: '专利交易',
                        linkUrl: "/commodity/buy?view=false&type=1"
                    }, {
                        name: '商标交易',
                        linkUrl: "/commodity/buy?view=false&type=2"
                    }, ],
                    type: 0,
                    linkUrl: "/commodity/buy",
                },
                {
                    name: '需求大厅',
                    child: [{
                        name: '版权需求',
                        linkUrl: "/requirement/list?view=false&type=0"
                    }, {
                        name: '专利需求',
                        linkUrl: "/requirement/list?view=false&type=1"
                    }, {
                        name: '商标需求',
                        linkUrl: "/requirement/list?view=false&type=2"
                    }, ],
                    type: 0,
                    linkUrl: "/requirement/list",
                },
                {
                    name: '知产配套',
                    child: [{
                            name: '知产服务',
                            linkUrl: ""
                        }, {
                            name: '版权运营',
                            linkUrl: ""
                        }, {
                            name: '版权监测',
                            linkUrl: ""
                        },
                        // {
                        //     name: '科创补贴',
                        //     linkUrl: ""
                        // },
                        {
                            name: '营销推广',
                            linkUrl: ""
                        },
                    ],
                    type: 1
                },
                {
                    name: '走进基地',
                    type: 0,
                    linkUrl: ""
                },
                {
                    name: '维权服务',
                    type: 0,
                    linkUrl: ""
                },
                {
                    name: '创作者',
                    linkUrl: "/requirement/IspList",
                    type: 0
                },
                {
                    name: '资讯中心',
                    type: 0,
                    linkUrl: ""
                },
                {
                    name: '品牌窗口',
                    child: [{
                            name: '原文荟',
                            linkUrl: ""
                        },
                        //     {
                        //     name: '声创汇',
                        //     linkUrl: ""
                        // },
                        {
                            name: '人是云',
                            linkUrl: ""
                        }
                    ],
                    type: 1
                },
                {
                    name: '关于我们',
                    type: 0,
                    linkUrl: ""
                },
            ],
            search: 0,
            show: false,
            activeMenu: [],
            keywords: '',
            searchType: [{
                    text: '需求',
                    value: 0
                },
                {
                    text: '商品',
                    value: 1
                },
                {
                    text: '创作者',
                    value: 2
                },
            ],
            unreadMessageCount: 0,
            cityName: '全国',
            bool: false

        }
    },

    watch: {
        $route(route) {
            // console.log('route.path:', route.path)
            // console.log(this.$route.path.indexOf('/wap'),'000000000000000000000000000')
            if (route.path == '/') {
                this.tapIndex = '首页'
            } else if (route.path == '/commodity/buy') {
                this.tapIndex = '知产交易'
            } else if (route.path == '/requirement/list') {
                this.tapIndex = '需求大厅'
            } else if (route.path == '/requirement/IspList') {
                this.tapIndex = '创作者'
            }
            window.sessionStorage.setItem("tabIndex", this.tapIndex)
            // console.log(this.isBool,'isBool');
        },
        cityName(newval) {
            console.log(newval);
        }




    },
    created() {
        localStorage.setItem('cityId', null)

        this.reveal()
        // window.sessionStorage.getItem("tabIndex") ||
        this.tapIndex = window.sessionStorage.getItem("tabIndex") || "首页"
        // TODO 退出后登录后不立即展示 需刷新
        if (getToken()) {
            api.getUnreadMessageCount().then(res => {
                this.unreadMessageCount = res.obj.count
            })
        }
    },
    methods: {
        doSomething() {
            this.bool = true

        },
        hot() {
            this.cities = true
            document.body.style.overflow = 'hidden';


        },
        //切换城市
        changeCity(v) {
            document.body.style.overflow = 'visible'
            console.log(v);
            this.cityName = v.cityName
            this.$bus.emit('changeMsg', v.cityId);
            localStorage.setItem('cityId', v.cityId)
            micros.actions.setGlobalState({
                cityId: v.cityId
            })
            this.cities = false
            this.bool = false

        },
        // alteration(){
        //     this.cities=true

        // },
        //城市
        reveal() {
            this.$api.otherApi.author.getProductStorePage({
                pageNo: 1,
                pageSize: 50,
            }).then(res => {
                // this.cityList=res.data.list
                console.log(res.data.list);
                // res.data.list.forEach(element => {
                // console.log(element.cityName);
                // let arrObjFilter = arrObj.filter(ele => ele.age > 18)
                let arr = res.data.list.map((ele) => {
                    return ele.cityName
                })
                this.cityList = res.data.list.filter((item, index) => {
                    return arr.indexOf(item.cityName) === index
                })
                let param = {
                    cityId: null,
                    cityName: "全国"

                }
                this.cityList.unshift(param)
                this.cityList.forEach((ite, i) => {
                    if (ite.cityName == null) {
                        this.cityList.splice(i, 1)
                    }
                });
                // console.log(this.cityList);

                // });

            })

        },
        searchKeywords() {
            if (this.keywords == '') {
                this.$toast('请输入关键词')
                return
            }

            if (this.search == 0) {
                this.jumpPageName('NeedList', {
                    keywords: this.keywords
                })
            }
            if (this.search == 1) {
                this.jumpPageName('BuyGoodsList', {
                    keywords: this.keywords
                })
            }
            if (this.search == 2) {
                this.jumpPageName('NeedIspList', {
                    keywords: this.keywords
                })
            }
            this.show = false
            this.keywords = ''
        },
        ...mapMutations({
            changeLoginStatus: "user/changeLoginStatus",
            setUserInfo: "user/setUserInfo",

        }),
        closeModel(e) {
            document.body.style.overflow = 'visible'
            e.preventDefault()
            this.show = false
        },
        // 去用户后台
        goSqsmBackPage(path) {
            window.open(config.yun_domain + path + '?token=' + this.$store.state.user.userInfo.token, '_blank')
        },

        goSqsmGoodsPage() {
            let userInfo = this.$store.state.user.userInfo
            // console.log(process.env.VUE_APP_SQSM_ADM + '?token=' + userInfo.token + '&usertype=' + userInfo.usertype, '_blank')
            window.open(process.env.VUE_APP_SQSM_ADM + '?token=' + userInfo.token + '&usertype=' + userInfo.usertype, '_blank')
        },
        // 登录
        login() {
            let newHref = window.location.href.replace(window.location.hash, '')
            if (document.body.clientWidth > 900) {
                window.location.href = config.yun_domain + "/acctlogin?referer_url=" + newHref;
            } else {
                window.location.href = config.loginUrlWap + "/acctlogin?referer_url=" + newHref;
            }
        },
        // 注册
        register() {
            window.open(config.yun_domain + "/acctlogin/register?referer_url=" + window.location.href);
        },
        // 退出登录
        loginOut() {
            this.$api.user.loginout().then(res => {
                if (res.status_code != 0) return this.$Message.warning({
                    content: res.status_msg
                })
                cookie.delCookie()
                this.changeLoginStatus(false);
                this.setUserInfo(null)
                window.localStorage.setItem('USER__TOKEN', "")
                window.localStorage.setItem('_RedirectData', "{}");
                micros.actions.setGlobalState({
                    globalToken: "",
                    url: location.href
                });
                window.location.href = '/'
            }).catch(err => {
                console.log(err)
            })
        },
        handleMenu() {
            document.body.style.overflow = 'hidden';
            this.cities = false
            this.show = true;
            this.$nextTick(() => {
                document.getElementsByClassName("mask")[0].style.height = document.body.scrollHeight + 'px';
            })
        },

        // 导航栏里的下拉框
        dropdownClick(data) {
            let indexs = data.split("-");
            if (indexs.length == 2) {
                this.jumpLink(1, indexs[0], indexs[1])
            }
        },

        dropOpenWinLick(name) {
            switch (name) {
                case 'kefu':
                    this.linkKefu()
                    break;
                case 'fankui':
                    this.assist()
                    break;
            }
        },

        // 联系客服
        contactKefu() {
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }
        },

        // 页面跳转
        jumpLink(type, parentIndex = 0, childIndex = 0, v) {
            console.log(v);
            this.show = false
            this.tapIndex = this.tabList[parentIndex].name
            window.sessionStorage.setItem("tabIndex", this.tapIndex)
            let pagePath = ""
            if (type == 1) {
                pagePath = this.tabList[parentIndex].child[childIndex].linkUrl;
            } else {
                pagePath = this.tabList[parentIndex].linkUrl;
            }
            if (pagePath != "") {
                this.$router.push({
                    path: pagePath
                })
                // window.history.pushState(null, null, pagePath)
            }
            if (v.name == "首页") {
                this.$router.push({
                    path: "/"
                })

            } else if (v.name == "走进基地") {
                this.$router.push({
                    path: "/enterBase"
                })

            } else if (v.name == "维权服务") {
                this.$router.push({
                    path: "/safeguard"
                })

            }
            if (v.name == "关于我们") {
                this.$router.push({
                    path: "/about"
                })

            }
            if (v.name == "资讯中心") {
                this.$router.push({
                    path: "/information"
                })

            }
        },

        // 跳转页面
        jumpPage(pagePath) {
            this.$router.push({
                path: pagePath
            })
        },

        jumpPageName(pageName, query) {
            this.$router.push({
                name: pageName,
                query
            })
        },
        //pc
        card(v) {
            console.log(v);

            if (v.name == "作品存证") {
                this.$router.push({
                    path: "/opus"
                })

            } else if (v.name == "网页取证") {
                this.$router.push({
                    path: "/webstore"
                })

            } else if (v.name == "公证翻译") {
                this.$router.push({
                    path: "/translate"
                })

            } else if (v.name == "公证提取") {
                this.$router.push({
                    path: "/deposit"
                })

            } else if (v.name == "零跑腿公证") {
                this.$router.push({
                    path: "/online"
                })

            } else if (v.name == "版权运营") {
                this.$router.push({
                    path: "/business"
                })

            } else if (v.name == "版权监测") {
                this.$router.push({
                    path: "/monitor"
                })

            } else if (v.name == "知产服务") {
                this.$router.push({
                    path: "/service"
                })

            } else if (v.name == "营销推广") {
                this.$router.push({
                    path: "/promotion"
                })

            } else if (v.name == "科创补贴") {
                this.$router.push({
                    path: "/subsidy"
                })

            }
            if (v.name == "原文荟") {
                window.open("https://ywh.renshihr.com/")


            }
            if (v.name == "人是云") {
                window.open("https://www.renshihr.com/")

            }



        },

        //h5
        cardWap(v) {
            document.body.style.overflow = 'visible'
            if (v.name == "作品存证") {
                this.$router.push({
                    path: "/wap/opus"
                })
                this.show = false
                // location.href=config.shiquanshimei+"/wap/opus"

            }
            if (v.name == "网页取证") {
                this.$router.push({
                    path: "/wap/webstore"
                })
                this.show = false
                // location.href=config.shiquanshimei+"/wap/webstore"

            }
            if (v.name == "公证翻译") {
                this.$router.push({
                    path: "/wap/translate"
                })
                this.show = false

                // location.href=config.shiquanshimei+"/wap/translate"

            }
            if (v.name == "公证提取") {
                this.$router.push({
                    path: "/wap/deposit"
                })
                this.show = false

            }
            if (v.name == "零跑腿公证") {
                this.$router.push({
                    path: "/wap/online"
                })
                this.show = false

            }
            if (v.name == "知产服务") {
                this.$router.push({
                    path: "/wap/service"
                })
                this.show = false


            }
            if (v.name == "版权运营") {
                this.$router.push({
                    path: "/wap/business"
                })
                this.show = false


            }
            if (v.name == "版权监测") {
                this.$router.push({
                    path: "/wap/monitor"
                })
                this.show = false


            }
            if (v.name == "科创补贴") {
                this.$router.push({
                    path: "/wap/subsidy"
                })
                this.show = false

            }
            if (v.name == "营销推广") {
                this.$router.push({
                    path: "/wap/promotion"
                })
                this.show = false
            }
            if (v.name == "原文荟") {
                window.open("https://ywh.renshihr.com/")


            }
            if (v.name == "人是云") {
                window.open("https://www.renshihr.com/")

            }


        },
        clear() {
            document.body.style.overflow = 'visible'
            this.show = false

        },
        jumpWap(v) {
            document.body.style.overflow = 'visible'


            if (v.name == "首页") {
                this.$router.push({
                    path: "/"
                })
                this.show = false


            }
            if (v.name == "走进基地") {
                this.$router.push({
                    path: "/wap/enterBase"
                })
                this.show = false
            }
            if (v.name == "维权服务") {
                this.$router.push({
                    path: "/wap/safeguard"
                })
                this.show = false
                // location.href=config.shiquanshimei+"/wap/safeguard"
            }
            if (v.name == "资讯中心") {
                this.$router.push({
                    path: "/wap/information"
                })
                this.show = false

            }
            if (v.name == "知产交易") {
                this.$router.push({
                    path: "/commodity/buy"
                })
                this.show = false
            } else if (v.name == "需求大厅") {
                this.$router.push({
                    path: "/requirement/list"
                })
                this.show = false
            } else if (v.name == "创作者") {
                this.$router.push({
                    path: "/requirement/IspList"
                })
                this.show = false
            }
            if (v.name == "关于我们") {
                this.$router.push({
                    path: "/wap/about"
                })
                this.show = false
            }


        },
        //联系qq客服
        linkKefu() {
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }
        },
        //帮助反馈
        assist() {
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }
        }
    },
}