import {
    DatetimePicker,
    DropdownMenu,
    DropdownItem,
    Swipe,
    SwipeItem,
    Tab,
    Tabs,
    Collapse,
    CollapseItem,
    Popup
} from 'vant';
import 'vant/lib/index.css';
import homeTitlepc from '@/components/home/homeTitlepc.vue'
import circleTab from '@/components/home/circleTab.vue'
import circleTabs from '@/components/home/circleTabs.vue'
import TabList from '@/components/home/tabList.vue'
import cardList from '@/components/home/cardList.vue'
import comTab from '@/components/home/comTab.vue'

import Header from '@/components/header/index.vue'
import Footer from '@/components/footer/index.vue'
import ContactUs from '@/components/contactUs/index.vue'
import kjbtContentChild from "@/components/home/kjbtContentChild.vue";
import kjbtContentChild2 from "@/components/home/kjbtContentChild2.vue";
import kjbtContentChildWap from "@/components/home/kjbtContentChildWap.vue";
import {
    swiper,
    swiperSlide
} from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import {
    request,
    config
} from "@/utils"
// import dataList from '../data.js'
export default {
    name: 'pageIndex',
    components: {
        DatetimePicker,
        Swipe,
        SwipeItem,
        DropdownMenu,
        DropdownItem,
        Tab,
        Tabs,
        Collapse,
        CollapseItem,
        Popup,
        homeTitlepc,
        circleTab,
        circleTabs,
        TabList,
        cardList,
        comTab,
        Header,
        Footer,
        ContactUs,
        kjbtContentChild,
        kjbtContentChild2,
        kjbtContentChildWap,
        swiper,
        swiperSlide,

    },
    computed: {
        curRoutePath() {
            return this.$route.path
        }
    },
    created() {
        this.getProductCategoryList()
        this.getGoodsPage()
        this.getNeedList(0)
        this.getNeedCategoryList()
        this.getProductStoreList()
        this.getNewsFlashList()

    },
    mounted(){

        this.$bus.on('changeMsg', (msg) => {
            this.cityId= msg
            this.getProductStoreList()
            this.getGoodsPage()
            this.getNeedList()
        });



     },
     watch: {

      },
    methods: {

        //h5跳转
        ipptWap(v){
            if(v=='公证服务'){
                location.href=config.shiquanshimei+"/wap/opus"

            }
            if(v=='知产服务'){
                location.href=config.shiquanshimei+"/wap/service"

            }
            if(v=='维权'){
                location.href=config.shiquanshimei+"/wap/safeguard"

            }
            if(v=='用权'){
                location.href=config.shiquanshimei+"/wap/subsidy"

            }
            if(v=="作品存证"){
                location.href=config.shiquanshimei+"/wap/opus"

            }
            if(v=="网页取证"){
                location.href=config.shiquanshimei+"/wap/webstore"

            }
            if(v=="公证翻译"){

                location.href=config.shiquanshimei+"/wap/translate"

            }
            if(v=="公证提存"){

                location.href=config.shiquanshimei+"/wap/deposit"

            }
            if(v=="零跑腿公证"){

                location.href=config.shiquanshimei+"/wap/online"
            }
            if(v=="走进基地"){

                location.href=config.shiquanshimei+"/wap/enterBase"
            }
            if(v=="维权服务"){

                location.href=config.shiquanshimei+"/wap/safeguard"
            }
            if(v=="客服"){
                // source(来源) 1:人是云    2:识权识美
                if(localStorage.getItem('_RedirectData') !=null){
                    let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                    let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                    window.open(url)
                }else{
                    let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                    window.open(url)
                }
            }
            if(v=="资讯中心"){

                location.href=config.shiquanshimei+"/wap/information"
            }
            if(v=="wz"){
                location.href=config.shiquanshimei+"/wap/details?name=《国家知识产权局 司法部关于加强新时代专利侵权纠纷行政裁决...&type=1&time=发布时间：2023-09-15&source=来源：国家知识产权局政务微信&status=1"
            }
            if(v=="w1"){
                location.href=config.shiquanshimei+"/wap/details?name=《企业知识产权合规管理体系要求》(GB/T 29490-2023)国家标准...&type=2&time=发布时间：2023-09-05&source=来源：国家知识产权局政务微信&status=1"
            }
            if(v=="w2"){
                location.href=config.shiquanshimei+"/wap/details?name=《专利（申请）权转让合同模板及签订指引》《专利实施许可合同...&type=3&time=发布时间：2023-06-30&source=来源：国家知识产权局政务微信&status=1"
            }
            if(v=="w3"){
                location.href=config.shiquanshimei+"/wap/details?name=《商品交易市场知识产权保护规范》国家标准解读&type=4&time=发布时间：2023-02-17&source=来源：国家知识产权局政务微信&status=1"
            }
            if(v=="w4"){
                location.href=config.shiquanshimei+"/wap/details?name=专利产品备案常见问题解答&type=5&time=发布时间：2022-11-25&source=来源：国家知识产权局政务微信&status=1"
            }

        },
        //资讯
        information(v,e){
            this.$router.push({
                path:'/details',
                query:{type:v,name:e}

            })

        },
        //登记
        registration(){
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }

        },
        //原文汇
        weihuiCheck(){
            window.open("https://ywh.renshihr.com/")

        },
        //人是云
        renshiCheck(){
            window.open("https://www.renshihr.com/")

        },
        // ----------------- 知识交易-------------------start
        // 获取知识交易相关产品数据

        getZsjyList(v) {
            this.$router.push({
                path:"/commodity/buy"
            })


            // console.log('index:', index)
            // if (type == 1) {
            //     this.comTabIndex = index;
            //     this.getLzTxtList(this.zxjyList, index)
            //     this.zcjyTabIndex = 0
            // }
            // if (type == 2) this.zcjyTabIndex = index
            // let curZsjyData = type == 1 && index == 0 ? this.zxjyList[index] : (this.gzListTxt[this.zcjyTabIndex] || {})
            // console.log('curZsjyData:', curZsjyData)
            // let params = {
            //     // proType: index == 0 && type == 1 ? null : 2,
            //     productCategoryId: v ,
            //     // recommendStatus: index == 0 && type == 1 ? 1 : null
            //     // sortFiles: index == 0 && type == 1 ? [{ field: 'views', order: 'desc' }] : null
            // }

            // this.$api.otherApi.product.getGoodsPage({ pageNo: 1, pageSize: 20, ...params }).then(res => {
            //     this.zcList = [...res.data.list]
            // })
        },
        getZsjyLists(title, name) {
            // console.log('name:', name)
            // console.log('111:', this.zhichanjyTab)
            this.zcjyTabIndex.push(title)
            this.zxjyList.forEach(ite => {
                if(ite.name == name){
                    this.gzListTxt=ite.children
                }

            });

            // if (type == 1) {
            //     this.comTabIndex = index;
            //     this.getZsjyLists(this.zxjyList, index)
            //     this.zcjyTabIndex = 0
            // }
            // if (type == 2) this.zcjyTabIndex = index
            // let curZsjyData = type == 1 && index == 0 ? this.zxjyList[index] : (this.gzListTxt[this.zcjyTabIndex] || {})
            // console.log('curZsjyData:', curZsjyData)
            // let params = {
            //     // proType: index == 0 && type == 1 ? null : 2,
            //     productCategoryId: curZsjyData.id || null,
            //     // recommendStatus: index == 0 && type == 1 ? 1 : null
            //     sortFiles: index == 0 && type == 1 ? [{ field: 'views', order: 'desc' }] : null
            // }

            // this.$api.otherApi.product.getGoodsPage({ pageNo: 1, pageSize: 20, ...params }).then(res => {
            //     this.zcList = [...res.data.list]
            // })
        },
        // getZsjyList(type, index) {
        //     console.log('index:', index)
        //     if (type == 1) {
        //         this.comTabIndex = index;
        //         this.getLzTxtList(this.zxjyList, index)
        //         this.zcjyTabIndex = 0
        //     }
        //     if (type == 2) this.zcjyTabIndex = index
        //     let curZsjyData = type == 1 && index == 0 ? this.zxjyList[index] : (this.gzListTxt[this.zcjyTabIndex] || {})
        //     console.log('curZsjyData:', curZsjyData)
        //     let params = {
        //         // proType: index == 0 && type == 1 ? null : 2,
        //         productCategoryId: curZsjyData.id || null,
        //         // recommendStatus: index == 0 && type == 1 ? 1 : null
        //         sortFiles: index == 0 && type == 1 ? [{ field: 'views', order: 'desc' }] : null
        //     }

        //     this.$api.otherApi.product.getGoodsPage({ pageNo: 1, pageSize: 20, ...params }).then(res => {
        //         this.zcList = [...res.data.list]
        //     })
        // },

        // 获取一级分类
        getProductCategoryList() {
            this.$api.otherApi.product.getListProductCategory().then(res => {
                // console.log(res.data,'res.data');
                // console.log(res.data[0].id,'res.data');

                this.zxjyList = [...this.zxjyList, ...res.data]
                this.getLzTxtList(this.zxjyList)
                this.zcjyTabIndex.push(this.zxjyList[0].id)
                // this.getZsjyList(res.data[0].id)
            })

        },
        getGoodsPage(){
            this.$api.otherApi.product.getProductStoresearch({ pageNo: 1, pageSize: 20,cityId:this.cityId,productStatus:3}).then(res => {
                this.zcList = res.list
        }   )

        },

        // 获取二级分类数据
        getLzTxtList(list, index = 1) {
            let gzListTxt = list[0].children;
            // for (const parentItem of list) {
            // if (list && list[index].children) {
            //     for (const childItem of list[index].children) {
            //         if (childItem.children) {
            //             gzListTxt = [...gzListTxt, ...childItem.children]
            //         }
            //     }
            // }
            // }
            // console.log('gzListTxt:', gzListTxt)
            this.gzListTxt = gzListTxt
        },

        // 获取交易快报
        getNewsFlashList() {
            this.$api.otherApi.product.getNewsflash().then(res => {
                this.newsFlashList = res.data || []
            })
        },

        // ----------------- 知识交易-------------------end

        // ----------------- 需求大厅-------------------start
        // 获取需求大厅得数据
        getNeedList(type, categoryIndex = -1) {
            this.xqTabIndex = type
            this.xqIndex = type
            if (categoryIndex > -1)
                this.sIndex = categoryIndex
            let params = {
                pageNo: 1,
                pageSize: 8,
                cityId:this.cityId,
                categoryOneId: categoryIndex >= 0 ? this.xqdtTabList[categoryIndex].id : null,
                // sortFiles: [{ field: type + 1, order: 'desc' }]
            }
            if (type === 0){
                params.sortFiles = [{ field: 'create_time', order: 'desc' }]
            }else if(type === 1){
                params.sortFiles = [{ field: 'join_num', order: 'desc' }]
            }
            this.$api.otherApi.need.getNeedPage(params).then(res => {
                this.xqdtList = [...res.data.list]
            })
        },
        // 获取侧边导航数据
        getNeedCategoryList() {
            this.$api.otherApi.need.getNeedCategory().then(res => {
                this.xqdtTabList = [...res.data]
            })
        },

        isTimeEnd(endTime) {
            let nowTime = new Date() // 当前时间
            let curTime = endTime - nowTime.getTime()
            if (curTime > 0) {
                let day = Math.floor(curTime / (24 * 3600 * 1000))
                let hours = Math.floor(curTime % (24 * 3600 * 1000) / (3600 * 1000))
                return { timeTxt: day + '天' + hours + '小时后停止投标', isEnd: false }
            } else {
                return { timeTxt: '已结束', isEnd: true }
            }
        },


        // ----------------- 需求大厅-------------------end
        // ----------------- 创作者-------------------start

        getProductStoreList() {
            this.$api.otherApi.author.getProductStorePage({
                pageNo: 1,
                pageSize: 10,
                sortFiles: [{ field: 'need_favorable_rate', order: 'desc' }],
                cityId:this.cityId
            }).then(res => {
                let czList = res.data.list
                czList.map((item) => {
                    if (item.personLabel) {
                        item.personLabel = JSON.parse(item.personLabel)
                    }
                    return item
                })
                // personLabel
                this.czList = [...czList]
            })
        },
        // ----------------- 创作者-------------------end

        search(){
            if(this.keywords == ''){
                // this.$toast('请输入关键词')
                // alert('请输入关键词')
                return
            }

            if (this.searchOption == 'need'){
                this.jumpPageName('NeedList',{ keywords: this.keywords })
            }
            if (this.searchOption == 'product'){
                this.jumpPageName('BuyGoodsList',{ keywords: this.keywords })
            }
            if (this.searchOption == 'isp'){
                this.jumpPageName('NeedIspList',{ keywords: this.keywords })
            }
        },

        // 跳转页面
        jumpPage(pagePath, isJump = true) {
            isJump && this.$router.push({ path: pagePath })
        },

        jumpPageName(pageName, query, isJump = true) {
            // if (isJump) {
            this.$router.push({ name: pageName, query })
            // }
        },

        // 导航栏链接跳转
        navLinkJump(index) {

        },

        toLink(index) {
            window.open(this.link[index])
        },
        changeAnchor(newHref) {
            this.activeLink = Number(newHref.slice(2)) - 1;
        },
        // 联系客服
        contactKefu(){
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }
        
        },
   

    },
    beforeDestroy(){
        this.$bus.off('changeMsg')
    },

    data() {
        return {
            newsFlashList: [],
            publicPath: process.env.BASE_URL,
            searchOption: 'need',
            //实时屏幕宽度
            windowWidth: document.documentElement.clientWidth,
            ppckIndex: 0,
            czztjIndex: 0,
            zcjyTabIndex:[],
            shlistIndex: 0,
            config,


            keywords: '',
            menu: ['公证服务', '知产交易', '需求大厅', '作者推荐', '维权服务', '知产配套', '版权监测', '品牌窗口', '资讯中心', '品牌优势'],
            activeLink: 0,
            zxjyList: [
                // { name: '热门推荐' }
            ],
            gzList: [{
                imgPath: require('./images/fushi.png'),
                txt: '服装'
            }, {
                imgPath: require('./images/zhubao.png'),
                txt: '珠宝'
            }, {
                imgPath: require('./images/jianzhu.png'),
                txt: '建筑'
            }, {
                imgPath: require('./images/shangbiao.png'),
                txt: '商标'
            }, {
                imgPath: require('./images/gongye.png'),
                txt: '工业'
            }, {
                imgPath: require('./images/yinyue.png'),
                txt: '音乐'
            }, {
                imgPath: require('./images/dongman.png'),
                txt: '动漫'
            }, {
                imgPath: require('./images/meishu.png'),
                txt: '美术'
            }, {
                imgPath: require('./images/sheying.png'),
                txt: '摄影'
            }, {
                imgPath: require('./images/duanshipin.png'),
                txt: '短视频'
            }, {
                imgPath: require('./images/wenzi.png'),
                txt: '文字'
            }, {
                imgPath: require('./images/qita.png'),
                txt: '其他'
            },],
            gzlist2: [{
                name: '新闻资讯>'
            },
            {
                name: '直播短视频>'
            },
            {
                name: '音乐歌曲>'
            },
            {
                name: '插画摄影>'
            },
            {
                name: '游戏形象、大热IP>'
            },
            {
                name: '电商平台>'
            },
            {
                name: '其它类型>'
            }
            ],
            gzListTxt: [],//'服装', '珠宝', '建筑', '商标', '工业', '音乐', '动漫', '美术', '摄影', '短视频', '文字', '其他'
            zcList: [],
            activeIndex: 0,
            xqTabIndex: 0,
            comTabIndex: 0,
            sIndex: 0,
            xqdtList: [],
            xqdtTabList: [],

            czList: [
            //     {
            //     name: '第一百次剃头事发地点',
            //     c: 25,
            //     l: 99,
            //     desc: '简介：毕业于广州美术学院，从业设计十余年。有良好的审美能力和理解沟通能力',
            //     imgPath: require('./images/author/1.png'),
            //     advs: ['工业设计师', '平面设计', 'UI设计', '包装设计']
            // },
            // {
            //     name: '蓝色花芯',
            //     c: 30,
            //     l: '100',
            //     desc: '简介：酷爱设计，为多家知名公司设计过LOGO。',
            //     imgPath: require('./images/author/2.png'),
            //     advs: ['工业设计师', '平面设计', 'UI设计', '包装设计']
            // },
            // {
            //     name: '临记',
            //     c: 12,
            //     l: 98,
            //     desc: '简介：擅长空间设计 、 家装设计，欢迎咨询。',
            //     imgPath: require('./images/author/3.png'),
            //     advs: ['工业设计师', '平面设计', 'UI设计', '包装设计']
            // },
            // {
            //     name: '巨灵文化传播',
            //     c: 100,
            //     l: 100,
            //     desc: '简介：巨灵设计z广州青年设计师协会，品牌设计策划。',
            //     imgPath: require('./images/author/4.png'),
            //     advs: ['工业设计师', '平面设计', 'UI设计', '包装设计']
            // },

            ],

            wqList: [{
                name: '李治国',
                y: '擅长：知识产权法、公司法、金融证券、投融资。',
                desc: '中国版权协会理事；广东省 知识产权局知识产权专家；广州律协版权委副主任。美国伊利诺伊理工大学，法学硕士.',
                imgPath: require('./images/protection/1.png')
            },
            {
                name: '黄胜友',
                y: '擅长：知识产权维权、民商事诉讼、企业法律顾问。',
                desc: '国家版权贸易基地（越秀） 维权专家。西南政法大学，法律硕士研究生。',
                imgPath: require('./images/protection/2.png')
            },
            {
                name: '刘杨',
                y: '擅长：知识产权法、公司法、金融证券、投融资。',
                desc: '国家商标品牌创新创业（广州）基地维权专家委员会委员。西南政法大学，法学理论硕士研究生。',
                imgPath: require('./images/protection/3.png')
            },
            {
                name: '韦海雁',
                y: '擅长：专利申请、专利无 效、科技项目培育、版权、 商标、专利侵权纠纷',
                desc: '第二届省知识产权保护协会专家。 毕业于大连海事大学。',
                imgPath: require('./images/protection/4.png')
            }
            ],

            fIndex: 0,
            fRightIndex: 0,
            sRightIndex: 0,
            bqIndex: 0,
            registerList: [{
                name: "商标高通注册",
                desc: "专家预审，高通过率，不成功退全款",
                imgPath: require('./images/zhiChanServes/1.1.png')
            },
            {
                name: "商标担保注册",
                desc: "一个工作日申报，不成功退全款",
                imgPath: require('./images/zhiChanServes/1.2.png')
            },
            {
                name: "商标双享注册",
                desc: "注册不成功，免费再注册一件",
                imgPath: require('./images/zhiChanServes/1.3.png')
            },
            {
                name: "商标设计+注册",
                desc: "商标设计+注册+版权保护，不成功退全款",
                imgPath: require('./images/zhiChanServes/1.4.png')
            },
            {
                name: "国际商标注册",
                desc: "全球200+个国家地区商标注册",
                imgPath: require('./images/zhiChanServes/1.5.png')
            },
            {
                name: "商标免费查询",
                desc: "注册前先查询，提高注册成功率",
                imgPath: require('./images/zhiChanServes/1.6.png')
            }
            ],
            sbbgList: [{
                name: "商标变更",
                desc: "变更注册人名义、地址等注册信息",
                type: '变更',
                imgPath: require('./images/zhiChanServes/1.7.png'),
            },
            {
                name: "商标转让",
                desc: "注册人将其名下商标转到他人名下",
                type: '转让',
                imgPath: require('./images/zhiChanServes/1.8.png')
            },
            {
                name: "商标续展",
                desc: "延长商标有效期",
                type: '续展',
                imgPath: require('./images/zhiChanServes/1.9.png')
            },
            {
                name: "商标宽展",
                desc: "商标到期后6个月希望继续持有，可申请",
                type: '宽展',
                imgPath: require('./images/zhiChanServes/1.10.png')
            },
            {
                name: "商标许可备案",
                desc: "授权他人使用商标",
                type: '备案',
                imgPath: require('./images/zhiChanServes/1.11.png')
            }
            ],
            sbwqList: [{
                name: "商标驳回复审",
                desc: "商标注册被驳回后的唯一救济方式",
                type: "复审",
                imgPath: require('./images/zhiChanServes/1.12.png')

            },
            {
                name: "商标异议答辩",
                desc: "注册最终关，答辩成功即拿证",
                type: "答辩",
                imgPath: require('./images/zhiChanServes/1.13.png')
            },
            {
                name: "商标无效宣告",
                desc: "宣告他人商标无效，扫清注册障碍",
                type: "宣告",
                imgPath: require('./images/zhiChanServes/1.14.png')
            },
            {
                name: "电商申述维权",
                desc: "解决电商被侵权被投诉难题",
                type: "维权",
                imgPath: require('./images/zhiChanServes/1.15.png')
            },
            {
                name: "无效驳回复审",
                desc: "授权他人使用商标",
                type: "复审",
                imgPath: require('./images/zhiChanServes/1.16.png')
            }
            ],
            zllcList: [{
                name: "专利撰写服务",
                desc: "专利撰写服务",
                imgPath: require('./images/zhiChanServes/2.4.png')
            },
            {
                name: "专利许可备案",
                desc: "保障专利交易过程，权利不受侵犯",
                imgPath: require('./images/zhiChanServes/2.5.png')
            },
            {
                name: "专利审查意见答复",
                desc: "答复官方意见，满足授权条件",
                imgPath: require('./images/zhiChanServes/2.6.png')
            },
            {
                name: "专利权评价报告",
                desc: "官网出具，专利含金量证明依据",
                imgPath: require('./images/zhiChanServes/2.7.png')
            },
            {
                name: "专利年费代缴",
                desc: "及时缴纳年费，防止专利无效",
                imgPath: require('./images/zhiChanServes/2.8.png')
            }
            ],
            zlwqList: [{
                name: "专利申请复审",
                desc: "专利申请驳回，为你提供复审咨询",
                imgPath: require('./images/zhiChanServes/2.9.png')
            },
            {
                name: "专利无效答辩",
                desc: "专业律师团队，维护正当权益",
                imgPath: require('./images/zhiChanServes/2.10.png')
            },
            {
                name: "专利民事诉讼",
                desc: "解决专利侵权民事问题",
                imgPath: require('./images/zhiChanServes/2.11.png')
            },
            {
                name: "专利行政诉讼",
                desc: "解决专利确权问题",
                imgPath: require('./images/zhiChanServes/2.12.png')
            }
            ],
            zlsqListTop: [
                "发明专利申请",
                "发明专利双享",
                "发明专利担保",
                "发明专利权保",
                "实用新型担保",
                "实用新型标准",
                "实用新型双享",
                "发明+实用",
                "外观专利标准",
                "外观专利担保"
            ],
            zlsqGzListTop: [
                "美国专利申请",
                "欧洲专利申请",
                "日本专利申请",
                "韩国专利申请",
                "巴黎公约申请",
                "PCT国际申请",
                "德国专利申请",
                "新加坡专利申请"
            ],
            zlsqList: [{
                name: "专利免费查询",
                desc: "先查询在申请，防止专利侵权",
                type: "查询",
                imgPath: require('./images/zhiChanServes/2.1.png')
            },
            {
                name: "外观专利担保申请",
                desc: "保护独特外观，低风险快速授权",
                type: "申请",
                imgPath: require('./images/zhiChanServes/2.2.png')
            },
            {
                name: "国际专利申请",
                desc: "全球200余国家/地区一站式服务",
                type: "申请",
                imgPath: require('./images/zhiChanServes/2.3.png')
            }
            ],

            bzzrList: [{
                name: "版权变更",
                desc: "版权登记后需更改登记信息",
                type: "变更"
            },
            {
                name: "版权转让",
                desc: "版权登记后需转让他人使用的",
                type: "转让"
            },
            {
                name: "版权补证",
                desc: "版权登记后需办理补证的，须提交申请",
                type: "补证"
            },
            {
                name: "免费查询",
                desc: "登记前先查询，有效提高成功率",
                type: "查询"
            },
            {
                name: "加急登记",
                desc: "根据用户需求，提供个性化登记服务，快至当天拿证",
                type: "咨询"
            }
            ],
            czfhList: [{
                name: "商标申请",
                desc: "商标早注册、品牌保护防火墙",
                imgPath: require('./images/kejiBuTie/1.7.png')
            },
            {
                name: "商业计划书",
                desc: "重塑商业模式，三分钟打动投资人",
                imgPath: require('./images/kejiBuTie/1.8.png')
            },
            {
                name: "代理记账",
                desc: "告别繁琐记账报税，节约创业每一分钟",
                imgPath: require('./images/kejiBuTie/1.9.png')
            },
            {
                name: "法律服务",
                desc: "资深律师，创业法务一站搞定",
                imgPath: require('./images/kejiBuTie/1.10.png')
            }
            ],
            kjzxList: [{
                name: "高企认定",
                desc: "奖励高达200万，减免40%所得税",
                imgPath: require('./images/kejiBuTie/1.1.png')
            },
            {
                name: "资金扶持",
                desc: "优享国家政策，助力企业发展",
                imgPath: require('./images/kejiBuTie/1.2.png')
            },
            {
                name: "可行性研究报告",
                desc: "精准分析评估，降低项目启动风险",
                imgPath: require('./images/kejiBuTie/1.3.png')
            },
            {
                name: "科技评估",
                desc: "专业团队评估，助力技术资产变现",
                imgPath: require('./images/kejiBuTie/1.4.png')
            },
            {
                name: "科技信息",
                desc: "科技信息全捕捉，竞争情报早知道",
                imgPath: require('./images/kejiBuTie/1.5.png')
            },
            {
                name: "规划研究",
                desc: "深度剖析发展现状，科学规划战略方向",
                imgPath: require('./images/kejiBuTie/1.6.png')
            }
            ],
            zzrzList: [{
                name: "资质申请",
                desc: "多年资质认证经验，150+产品任选",
                imgPath: require('./images/kejiBuTie/1.11.png')
            },
            {
                name: "双软评估",
                desc: "招投标双软助推，增值税即征即退",
                imgPath: require('./images/kejiBuTie/1.12.png')
            },
            {
                name: "体系认证",
                desc: "专业团队辅导，权威机构发证",
                imgPath: require('./images/kejiBuTie/1.13.png')
            },
            {
                name: "软著登记",
                desc: "快速高效办理，担保登记无后顾之忧",
                imgPath: require('./images/kejiBuTie/1.14.png')
            },
            {
                name: "3C认证",
                desc: "市场敲门砖，提高市场占有率",
                imgPath: require('./images/kejiBuTie/1.15.png')
            },
            {
                name: "知产贯标",
                desc: "优先获得政府补助支持",
                imgPath: require('./images/kejiBuTie/1.16.png')
            }
            ],
            searchList: [{
                imgPath: require('./images/kejiBuTie/2.1.png'),
                desc: '技术难题对接'
            },
            {
                imgPath: require('./images/kejiBuTie/2.2.png'),
                desc: '技术中试'
            },
            {
                imgPath: require('./images/kejiBuTie/2.3.png'),
                desc: '资产评估'
            },
            {
                imgPath: require('./images/kejiBuTie/2.4.png'),
                desc: '产学研合作'
            },
            {
                imgPath: require('./images/kejiBuTie/2.5.png'),
                desc: '共建创新平台'
            },
            {
                imgPath: require('./images/kejiBuTie/2.6.png'),
                desc: '技术难题攻关'
            },
            ],
            zzcList: [{
                imgPath: require('./images/kejiBuTie/3.1.png'),
                desc: '政策匹配'
            },
            {
                imgPath: require('./images/kejiBuTie/3.2.png'),
                desc: '高企测评'
            },
            {
                imgPath: require('./images/kejiBuTie/3.3.png'),
                desc: '政策咨询'
            },
            {
                imgPath: require('./images/kejiBuTie/3.4.png'),
                desc: '商业计划书'
            },
            {
                imgPath: require('./images/kejiBuTie/3.5.png'),
                desc: '精选资金'
            },
            {
                imgPath: require('./images/kejiBuTie/3.6.png'),
                desc: '科技保险'
            },
            ],
            bqyyList: [{
                name: '保证版权权利',
                desc: '仅进行代理服务',
                imgPath: require('./images/banquanYunYing/1.1.png'),
            }, {
                name: '帮助版权作品',
                desc: '清晰定位运营方向',
                imgPath: require('./images/banquanYunYing/1.2.png'),
            }, {
                name: '制定长期稳定',
                desc: '运营计划',
                imgPath: require('./images/banquanYunYing/1.3.png'),
            }, {
                name: '利用多元化',
                desc: '平台整合资源',
                imgPath: require('./images/banquanYunYing/1.4.png'),
            }, {
                name: '版权保护、版权监测',
                desc: '版权维权一站式服务',
                imgPath: require('./images/banquanYunYing/1.5.png'),
            }],
            yxtgList: [{
                name: '市场分析',
                desc: '分析市场，了解对手，赋予产品竞争力。',
                imgPath: require('./images/yingxiaoTuiGuang/1.1.png'),
            }, {
                name: '产品定位',
                desc: '定位产品优势及客户群体，实现爆发时增长。',
                imgPath: require('./images/yingxiaoTuiGuang/1.2.png'),
            }, {
                name: '卖点包装',
                desc: '全方位包装产品，打造产品核心卖点。',
                imgPath: require('./images/yingxiaoTuiGuang/1.3.png'),
            }, {
                name: '热点借势',
                desc: '产品与热点话题集合，扩大产品影响力',
                imgPath: require('./images/yingxiaoTuiGuang/1.4.png'),
            }, {
                name: '精准营销',
                desc: '精准定位客户，方便快速购买。',
                imgPath: require('./images/yingxiaoTuiGuang/1.5.png'),
            }],
            bqdjList: [{
                name: '小说',
                desc: '登记版权才能受法律保护',
                imgPath: require('./images/zhiChanServes/3.1.png')
            }, {
                name: '论文',
                desc: '登记后，可发表至期刊等平台',
                imgPath: require('./images/zhiChanServes/3.2.png')
            }, {
                name: '剧本',
                desc: '登记后，可出售他人拍摄影师作品',
                imgPath: require('./images/zhiChanServes/3.3.png')
            }, {
                name: '商业计划书',
                desc: '防止商业机密被他人窃取',
                imgPath: require('./images/zhiChanServes/3.4.png')
            }, {
                name: '歌词',
                desc: '保护原创，有效不免剽窃，盗窃行为',
                imgPath: require('./images/zhiChanServes/3.5.png')
            }, {
                name: '加急登记',
                desc: '提供个性化登记服务',
                imgPath: require('./images/zhiChanServes/3.6.png')
            }],
            rzzpList: [
                {
                    name: '网站',
                    desc: '打击网页抄袭者的有效证据',
                    imgPath: require('./images/banquandengji/2.1.png')
                },
                {
                    name: '电脑软件',
                    desc: '让软件抄袭者赔偿的证据之一',
                    imgPath: require('./images/banquandengji/2.2.png')
                },
                {
                    name: 'APP',
                    desc: '登记后，可提升公司或项目估值',
                    imgPath: require('./images/banquandengji/2.3.png')
                },
                {
                    name: '源代码',
                    desc: '防止你的源代码被他人复制',
                    imgPath: require('./images/banquandengji/2.4.png')
                },
                {
                    name: '软著担保登记',
                    desc: '6-12个工作日',
                    imgPath: require('./images/banquandengji/2.5.png')
                },
            ],
            mszpList: [
                {
                    name: 'LOGO',
                    desc: '全面维护品牌形象，防止被假冒',
                    imgPath: require('./images/banquandengji/3.1.png')
                },
                {
                    name: '书画',
                    desc: '防止你的书画艺术被他人临摹',
                    imgPath: require('./images/banquandengji/3.2.png')
                },
                {
                    name: '漫画',
                    desc: '保护原创漫画正当授权',
                    imgPath: require('./images/banquandengji/3.3.png')
                },
                {
                    name: '卡通形象',
                    desc: '登记后，才能有偿授权他人使用',
                    imgPath: require('./images/banquandengji/3.4.png')
                },
                {
                    name: '平面网页',
                    desc: '打击网页抄袭者的有效证据',
                    imgPath: require('./images/banquandengji/3.5.png')
                },
                {
                    name: '加急登记',
                    desc: '根据用户需求，提供个性化登记服务',
                    imgPath: require('./images/banquandengji/3.6.png')
                },
            ],
            syzpList: [
                {
                    name: '照片',
                    desc: '避免照片被用于不正当商业、出版、展览',
                    imgPath: require('./images/banquandengji/4.1.png')
                },
                {
                    name: '写真',
                    desc: '保护人物肖像、艺术摄影，免受不正当商业行为',
                    imgPath: require('./images/banquandengji/4.2.png')
                },
                {
                    name: '人物',
                    desc: '避免照片被用于不正当商业、出版、展览',
                    imgPath: require('./images/banquandengji/4.3.png')
                },
                {
                    name: '动物',
                    desc: '避免照片被用于不正当商业、出版、展览',
                    imgPath: require('./images/banquandengji/4.4.png')
                },
                {
                    name: '建筑',
                    desc: '避免照片被用于不正当商业、出版、展览',
                    imgPath: require('./images/banquandengji/4.5.png')
                },
                {
                    name: '风景',
                    desc: '避免照片被用于不正当商业、出版、展览',
                    imgPath: require('./images/banquandengji/4.6.png')
                },
            ],
            yszpList: [
                {
                    name: '电视剧',
                    desc: '控告他人抢播、盗播的有效证据',
                    imgPath: require('./images/banquandengji/5.1.png')
                },
                {
                    name: '宣传片',
                    desc: '控告他人盗用创意的有效证据',
                    imgPath: require('./images/banquandengji/5.2.png')
                },
                {
                    name: '动画片',
                    desc: '防止动画元素被盗用',
                    imgPath: require('./images/banquandengji/5.3.png')
                },
                {
                    name: '电影',
                    desc: '登记后，可有偿授权他人播放',
                    imgPath: require('./images/banquandengji/5.4.png')
                },
                {
                    name: '综艺节目',
                    desc: '防止节目流程被抄袭、模仿',
                    imgPath: require('./images/banquandengji/5.5.png')
                },
                {
                    name: '视频短片',
                    desc: '保护视频不被非法篡改或翻录',
                    imgPath: require('./images/banquandengji/5.6.png')
                },
            ],
            yShuzpList: [
                {
                    name: '歌曲',
                    desc: '打击恶意翻唱、改编行为的有效证据',
                    imgPath: require('./images/banquandengji/6.1.png')
                },
                {
                    name: '音乐',
                    desc: '登记后，可有偿授权指定平台传播',
                    imgPath: require('./images/banquandengji/6.2.png')
                },
                {
                    name: '铃声',
                    desc: '有效避免剽窃、盗窃行为',
                    imgPath: require('./images/banquandengji/6.3.png')
                },
                {
                    name: '舞蹈',
                    desc: '登记后，可有偿授权指定平台传播',
                    imgPath: require('./images/banquandengji/6.4.png')
                },
                {
                    name: '曲艺',
                    desc: '登记后，可有偿授权指定平台传播',
                    imgPath: require('./images/banquandengji/6.5.png')
                },
                {
                    name: '戏剧',
                    desc: '登记后，可有偿授权指定平台传播',
                    imgPath: require('./images/banquandengji/6.6.png')
                },
            ],
            gczpList: [
                {
                    name: '工程设计图纸',
                    desc: '向侵权者追讨赔偿金的有效证据',
                    imgPath: require('./images/banquandengji/7.1.png')
                },
                {
                    name: '产品设计图',
                    desc: '确定版权归属，实现版权收入',
                    imgPath: require('./images/banquandengji/7.2.png')
                },
                {
                    name: '地图',
                    desc: '控告侵权者公然侵权的有效证据',
                    imgPath: require('./images/banquandengji/7.3.png')
                },
                {
                    name: '电路示意图',
                    desc: '确认电路示意图著作权',
                    imgPath: require('./images/banquandengji/7.4.png')
                },
                {
                    name: '服装设计图',
                    desc: '防止服装样式被抄袭',
                    imgPath: require('./images/banquandengji/7.5.png')
                },
                {
                    name: '加急登记',
                    desc: '根据用户需求，提供个性化登记服务',
                    imgPath: require('./images/banquandengji/7.6.png')
                },
            ],
            bqbgList: [{
                name: '版权变更',
                desc: '版权登记后需更改登记信息',
                imgPath: require('./images/zhiChanServes/3.7.png')
            }, {
                name: '版权转让',
                desc: '版权登记后需转让他人使用的',
                imgPath: require('./images/zhiChanServes/3.8.png')
            }, {
                name: '版权补证',
                desc: '版权登记后需办理补证的，须提交申请',
                imgPath: require('./images/zhiChanServes/3.9.png')
            }, {
                name: '免费查询',
                desc: '登记前先查询，有效提高成功率',
                imgPath: require('./images/zhiChanServes/3.10.png')
            }, {
                name: '加急登记',
                desc: '根据用户需求，提供个性化登记服务，快至当天拿证',
                imgPath: require('./images/zhiChanServes/3.11.png')
            }],

            kIndex: 0,
            kRightIndex: 0,

            bqjcTabList: ['新闻咨询', '绘画摄影', '短视频', '音乐歌曲', '动漫游戏', '电商', '其他'],
            bqjcList: [{
                    name: '新闻资讯',
                    desc: '对原创文章、新闻资讯被复制、转载、不合规引用情况的监测。',
                    imgPath: require('./images/banquanJianCe/banner1.png')
                },
                {
                    name: '插画摄影',
                    desc: '对素材网站、社交网站等展示的作品进行对比，监测盗图行为',
                    imgPath: require('./images/banquanJianCe/banner2.png')
                },
                {
                    name: '直播短视频',
                    desc: '实时监测短视频直播平台的侵权内容',
                    imgPath: require('./images/banquanJianCe/banner3.png')
                },
                {
                    name: '音乐歌曲',
                    desc: '对音乐平台、广告宣传片、商业物料等进行监测，发现未授权音乐使用信息',
                    imgPath: require('./images/banquanJianCe/banner4.png')
                },
                {
                    name: '游戏形象、大热IP',
                    desc: '影视、动画IP等热播时，结合技术监测及人工审核，快速下架侵权内容',
                    imgPath: require('./images/banquanJianCe/banner5.png')
                },
                {
                    name: '电商平台',
                    desc: '对电商平台展示的服饰照片、产品图片等进行监测对比，排查重合图片信息',
                    imgPath: require('./images/banquanJianCe/banner6.png')
                },
                {
                    name: '其他类型',
                    desc: '支持影视综艺等其他作品进行监测',
                    imgPath: require('./images/banquanJianCe/banner7.png')
                },
            ],
            bqjcTabIndex: 0,
            bqjcTabIndexWap: 0,
            swiperOption: {
                loop: true,
                // spaceBetween: 10,
                slidesPerView: 1,
                centeredSlides: true,
                slideToClickedSlide: true,
                grabCursor: true,
                keyboard: {
                    enabled: true,
                },
                pagination: {
                    el: '.swiper-pagination1'
                },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                // autoplay: {
                //     delay: 4000, // 自动播放的间隔时间，单位ms，默认3000ms
                //     disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，即停止。改为false，表示不会停止自动播放
                // },
                on: {
                    // 使用es6的箭头函数，使this指向vue对象
                    slideChange: () => {
                        // 通过$refs获取对应的swiper对象
                        let swiper = this.$refs.mySwiper.swiper;
                        this.bqjcTabIndex = swiper.realIndex;

                        let swiper2 = this.$refs.mySwiper2.swiper;
                        this.bqjcTabIndexWap = swiper2.realIndex;

                    }
                }
            },

            wenHuiTabList: ['房产', '汽车', '娱乐', '旅游', '体育', '情感', '科技互联网', '美妆时尚', '时事政治', '更多'],
            wenHuiTabIndex: 0,
            wenHuiList: [{
                    title: '未合规缴纳公积金,行政处罚影响...',
                    name: '张晓晓',
                    time: '2021-7-26',
                    imgPath: require('./images/pinpaiChuangKou/1.1.png')
                },
                {
                    title: '修改通过! 都有哪些变化...(附条文...',
                    name: '武彤',
                    time: '2021-7-25',
                    imgPath: require('./images/pinpaiChuangKou/1.2.png')
                },
                {
                    title: '支持灵活就业倡议书发布平台企业...',
                    name: '宁一i',
                    time: '2021-7-26',
                    imgPath: require('./images/pinpaiChuangKou/1.3.png')
                },
                {
                    title: '提醒一下,分公司不能独立享受得税...',
                    name: '孙晓波',
                    time: '2021-7-25',
                    imgPath: require('./images/pinpaiChuangKou/1.4.png')
                },
                {
                    title: '员工迟到3次被辞退,这家公司HR厉...',
                    name: '李明月',
                    time: '2021-7-25',
                    imgPath: require('./images/pinpaiChuangKou/1.5.png')
                },
                {
                    title: '员工托人打卡,属于违纪吗?可以开...',
                    name: '刘苏',
                    time: '2021-7-25',
                    imgPath: require('./images/pinpaiChuangKou/1.7.png')
                },
                {
                    title: '下半年将被干掉的10...',
                    name: '明晃晃',
                    time: '2021-7-24',
                    imgPath: require('./images/pinpaiChuangKou/1.6.png')
                },
                {
                    title: '员工托人打卡,属于...',
                    name: '清丽iu',
                    time: '2021-7-24',
                    imgPath: require('./images/pinpaiChuangKou/1.8.png')
                },
            ],
            brandAdvantagesList: [{
                    imgPath: require('./images/brandAdvantages/1.png'),
                    desc: '公证赋能',
                },
                {
                    imgPath: require('./images/brandAdvantages/2.png'),
                    desc: '形式灵活',
                },
                {
                    imgPath: require('./images/brandAdvantages/3.png'),
                    desc: '安全快捷',
                },
                {
                    imgPath: require('./images/brandAdvantages/4.png'),
                    desc: '平台稳健',
                },
                {
                    imgPath: require('./images/brandAdvantages/5.png'),
                    desc: '服务闭环',
                },
                {
                    imgPath: require('./images/brandAdvantages/6.png'),
                    desc: '跨行结合',
                },
            ],
            activeTab: 1,
            zhichanjyTab: 0,
            xqdtTab: 0,
            xqIndex: 0,
            zxzxTab: 0,
            activeZcfw: 0,
            zcptTab1: 0,
            zcptTab2: 0,
            zcptTab3: 0,
            activeKjfw: 0,
            kjfwTab: 0,
            activeNames: [],
            activeNames2: [],
            shlistIndex: 0,
            aboutMe: [
                '联系方式',
                '公司资质',
                '支付方式',
                '意见反馈',
                '帮助中心',
                '条款协议'
            ],

            pstyle: {
                left: 0,
                top: 0
            },
            cityId:Number(localStorage.getItem('cityId')),
            bool:false

        }
    }
}
